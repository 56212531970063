<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <Main msg="Socks App"/>
</template>

<script>
import Main from "@/components/Main";
export default {
  name: 'App',
  components: {Main}
}
</script>
